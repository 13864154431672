
import React, { useState, useEffect,useRef } from "react";
import './App.css';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Card from '@mui/material/Card';
import StepLabel from '@mui/material/StepLabel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { Slide } from '@mui/material';
import emailjs from 'emailjs-com';
import 'animate.css/animate.min.css';
import ReCaptcha from 'react-google-recaptcha';




const App = () => {
  // define state variables
  const [ideas, setIdeas] = useState([]);
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [input4, setInput4] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentField, setCurrentField] = useState(1); // add state variable to keep track of current field
  const shareButton = document.querySelector('#share-button');
  const [formIsValid, setFormIsValid] = useState(false);
  const [isRecaptchaCompleted, setIsRecaptchaCompleted] = useState(false);

  const handleRecaptcha = (value) => {
    setIsRecaptchaCompleted(true);
  }
  
  

  const element = document.querySelector('.fade-field');
// using Twilio SendGrid's v3 Node.js Library
// https://github.com/sendgrid/sendgrid-nodejs



  
 // define event handlers for the arrow buttons
 const handlePrevious = () => {
  if (currentField > 1) {
    setCurrentField(currentField - 1);
  }
};
const handleNext = () => {
  if (currentField === 4) {
    alert("Please press the generate button to complete the form.");
    return;
  }
  if (currentField < 4) {
    switch (currentField) {
      case 1:
        if (input1 !== '') {
          setFormIsValid(true);
          setCurrentField(currentField + 1);
        } else {
          setFormIsValid(false);
        }
        break;
      case 2:
        if (input2 !== '') {
          setFormIsValid(true);
          setCurrentField(currentField + 1);
        } else {
          setFormIsValid(false);
        }
        break;
        case 3:
        if (input3 !== '') {
          setFormIsValid(true);
          setCurrentField(currentField + 1);
        } else {
          setFormIsValid(false);
        }
        break;
      default:
        setFormIsValid(false);
    }
  }
};


const [animate, setAnimate] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);
const [isSubmitted, setIsSubmitted] = useState(false);

const handleSubmit = async () => {
  /*if (!isRecaptchaCompleted) {
    alert("Please complete the reCAPTCHA before submitting.");
    return;
  } */
    try {

        emailjs.send('service_bgbqrar', 'template_ufkoybp', {
            "from_name": "Your Name",
            "to_name": 'epiclandingpage@gmail.com',
            "product_service": input1,
            "target": input2,
            "goals": input3,
            "generated_ideas": ideas, 
            "email": input4,
        }, 'Ytr3yCTSnC6_HDUdE').then(() => {
            setAnimate(true);
            setTimeout(() => {
              setAnimate(false);
            }, 1000);
        });
        setIsSubmitting(false);
        setIsSubmitted(true);
    } catch (error) {
        console.error(error);
        alert('Error occurred while sending email, please try again later.');
        setIsSubmitting(false);
    }
 
};

const handleAnimationEnd = () => {
  buttonRef.current.classList.remove('animated', 'bounce');
  buttonRef.current.classList.remove('animate__animated', 'animate__bounce');
};



const buttonRef = useRef(null);
useEffect(() => {
  if (animate) {
    buttonRef.current.classList.add('animated', 'bounce');
    buttonRef.current.addEventListener('animationend', handleAnimationEnd);
  } else {
    buttonRef.current.classList.remove('animated', 'bounce');
    buttonRef.current.removeEventListener('animationend', handleAnimationEnd);
  }
}, [animate]);


// render the current field based on the currentField state variable
let field;
switch (currentField) {
  case 1:
    field = (
      <Slide in={currentField === 1} timeout={400} unmountOnExit>
      <div>
        <label>Product/Service</label>
        <p>Please describe the product or service that your company offers.
          What problem does your product or service solve, and how does it benefit your customers?</p>
       <div> <input value={input1} onChange={(e) => setInput1(e.target.value)} required /></div>
      </div>
      </Slide>
    );
    break;
  case 2:
    field = (
      <Slide in={currentField === 2} direction="left" timeout={400} unmountOnExit>
      <div>
        <label>Target Audience</label>
        <p>Who is the primary audience that your product or service is intended for? Be as specific as possible.</p>
        <div><input value={input2} onChange={(e) => setInput2(e.target.value)} required/></div>
      </div>
      </Slide>
    );
    break;
  case 3:
    field = (
      <Slide in={currentField === 3} timeout={400} unmountOnExit>
      <div>
        <label>Business Goals</label>
        <p>What are your company's long-term goals or aspirations? This could include financial, growth, or impact goals.</p>
       <div> <input value={input3} onChange={(e) => setInput3(e.target.value)} required/> </div>
      </div>
      </Slide>
    );
    break;
    case 4:
      field = (
        <Slide in={currentField === 4} timeout={400} unmountOnExit>
        <div>
          <label>Email</label>
          <p>Give us your email and we will send you landing page design ideas.</p>
         <div> <input value={input4} onChange={(e) => setInput4(e.target.value)} required/> </div>
        </div>
        </Slide>
      );
      break;
  default:
    field = null;
}




  const LoadingAnimation = () => (

    
    <div className='container'>
  <svg width="135" height="60" viewBox="0 0 100 60">
    <rect x="0" y="0" width="10" height="60" rx="6">
      <animate attributeType="CSS" 
               attributeName="height" 
               values="60;20;60;"
               begin='0s'
               dur="1s"
               repeatCount="indefinite" />
      <animate attributeType="CSS" 
               attributeName="y" 
               begin='0s'
               values="0;20;0;"
               dur="1s"
               repeatCount="indefinite" />
    </rect>
    <rect x="20" y="0" width="10" height="60" rx="6">
      <animate attributeType="CSS" 
               attributeName="height" 
               values="60;20;60"
               begin='0.2s'
               dur="1s"
               repeatCount="indefinite" />
      <animate attributeType="CSS" 
               attributeName="y" 
               values="0;20;0"
               begin='0.2s'
               dur="1s"
               repeatCount="indefinite" />
    </rect>
    <rect x="40" y="0" width="10" height="60" rx="6">
      <animate attributeType="CSS" 
               attributeName="height" 
               values="60;20;60"
               begin='0.4s'
               dur="1s"
               repeatCount="indefinite" />
      <animate attributeType="CSS" 
               attributeName="y" 
               values="0;20;0"
               begin='0.4s'
               dur="1s"
               repeatCount="indefinite" />
    </rect>
    <rect x="60" y="0" width="10" height="60" rx="6">
      <animate attributeType="CSS" 
               attributeName="height" 
               values="60;20;60"
               begin='0.6s'
               dur="1s"
               repeatCount="indefinite" />
      <animate attributeType="CSS" 
               attributeName="y" 
               values="0;20;0"
               begin='0.6s'
               dur="1s"
               repeatCount="indefinite" />
    </rect>
    <rect x="80" y="0" width="10" height="60" rx="6">
      <animate attributeType="CSS" 
               attributeName="height" 
               values="60;20;60"
               begin='0.8s'
               dur="1s"
               repeatCount="indefinite" />
      <animate attributeType="CSS" 
               attributeName="y" 
               values="0;20;0"
               begin='0.8s'
               dur="1s"
               repeatCount="indefinite" />
    </rect>
  </svg>
</div>
  );
  


 
  

  // define the generateIdeas function
  const generateIdeas = async () => {
    console.log("Generating ideas...");
    setIsLoading(true); 
    const combinedPrompt = `I want you to act as a top tier landing page consultant for web design agency. As an epic landing page/ web design consultant for businesses, give me ideas for a ${input1} business that is looking to drive engagement and conversion through the use of unique promotions and features that are optimized for search engines (SEO). In order to achieve my business goals of ${input3}, what ideas would your recommend for promotions, features, and UX/UI design aesthetics that will be most effective for my target market of ${input2}. can you also give me an example page layout based this information.`;
    const obj = { /* object definition here */ };
    const value = obj?.[0];
 // value will be undefined if obj is undefined
    // console.log(`Prompt: ${combinedPrompt}`);
  
    const apiKey = "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";
    const response = await fetch("https://api.openai.com/v1/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        model: "text-davinci-003",
        prompt: combinedPrompt,  // use the combined prompt as the input
        temperature: 0.9,
        max_tokens: 800,
        top_p: 1,
        frequency_penalty: 0.0,
        presence_penalty: 0.6,
      })
    });


    console.log(`Response received: ${response}`);
    const data = await response.json();
    console.log(`Data: ${data}`);
    setIdeas(data.choices[0].text.split("\n"));
setIdeas(data.choices[0].text.split("\n"));
setIsLoading(false);  // set isLoading to false

shareButton.addEventListener('click', () => {
  const subject = 'My Ideas';
  const body = data.choices[0].text;
  window.location.href = `mailto:?subject=${subject}&body=${body}`;
});


  };
  
  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    return () => document.removeEventListener('touchstart', handleTouchStart);
  }, []);
  
  const handleTouchStart = (event) => {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  };
  
  useEffect(() => {
    if (isLoading && element) {
      element.style.opacity = 1;
      element.classList.add('fade-field');
    }
  }, [isLoading, element]);
  // import the model-viewer script and log a message when the page is loaded
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "module";
    document.body.appendChild(script);

    console.log("Page is loaded!");
  }, []);
  useEffect(() => {
    const element = document.querySelector('.response');
    if (element) {
      setIsLoading(false); // set isLoading to false if the response is in the DOM
    }
  }, []);

  

  return (
    <div className="container">
      <form onSubmit={e => {
        e.preventDefault();
        generateIdeas();
      }}>
        <img className="logo" src="https://epiclandingpages.com/img/epc-1.png"></img><br /><br />
        <label className="form-label">Hello, im EPC, Your Ai Landing Page Consultant.  </label>
        <p className="form-details">Please tell me more about your business</p>
        <br />
        <br />
        <div className="fade-field">{field}</div> {/* add className attribute to the element */}
        <div className="form-buttons">
       <a onClick={handlePrevious}>
        <Button
        variant="contained"
        className="button-secondary"
        size="small"
        startIcon={<ArrowBackIosIcon 
          
        />}
      >
        Back
      </Button>
      </a>
         
         
         <a 
         disabled={currentField === 4}
  onClick={handleNext}
  className="next-button">
          <Button
        variant="contained"
        className="button-secondary"
        size="small"
        endIcon={<ArrowForwardIosIcon />}
       
      >
        Next
      </Button>

      </a>

     


        </div>
        <div>
<Stepper className="stepper" activeStep={currentField - 1}>
  <Step>
    <StepLabel>Product/Service</StepLabel>
  </Step>
  <Step>
    <StepLabel>Target Audience</StepLabel>
  </Step>
  <Step>
    <StepLabel>Business Goals</StepLabel>
  </Step>
</Stepper>

</div>

        <button type="submit" className="button-primary generate-ideas" disabled={!formIsValid}>Generate Ideas</button>

      </form>
      {isLoading ? (
        <div className="loading"><LoadingAnimation /></div>
      ) : (
        <Card className="ideas-list">
         {ideas.map((idea, index) => (
  <p key={index}>{idea}</p>
))}

        </Card>
      )}
      
    {ideas.length > 0 &&  <p>Send us Ep's response and get a free landing page design. </p>}
    <ReCaptcha
  sitekey={'6LeZ7ukjAAAAAK5IKn4KREZqRR96iJfbA0Wy4QS2'}
  onChange={handleRecaptcha}
/>  
    <button 
    ref={buttonRef} 
    className={`btn ${ideas.length > 0 ? "button-secondary" : ""} ${isSubmitting ? "animate__animated animate__bounce" : animate ? "animate__animated animate__bounce" : isSubmitted ? "animate__animated animate__fadeIn" : ""}`}
    
    onClick={ideas.length > 0 ? handleSubmit : null}
    onAnimationEnd={handleAnimationEnd}
    key={ideas.length > 0 ? "share-button" : "send-results"}
  >
    {isSubmitted ? "Form Submitted!" : ideas.length > 0 ? "Send Response" : "Send Results"}
  </button>






  


    </div>


  );
  
  
};  


export default App;

